
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/miniSite/[partnerId]",
      function () {
        return require("private-next-pages/miniSite/[partnerId]/index.miniSite.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/miniSite/[partnerId]"])
      });
    }
  