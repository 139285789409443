import Head from "next/head";
import {decode} from 'html-entities';
import {ReactNode} from "react";

interface IHeadSeo {
    children?: ReactNode;
    seo?: any;
}

const HeadSeo = ({ children, seo }: IHeadSeo) => {
    //console.log('seo.title', seo.title)

    if(!seo) {
        return (
            <Head data-cy={'head-seo'}>
                <title>
                    Доставка еды: заказ вкусной еды на дом | Edostav.ru
                </title>
                <meta
                    name="keywords"
                    content="доставка еды, заказ, купить, отзывы, рейтинги, Edostav.ru"
                />
                <meta
                    name="description"
                    content="Заказ и доставка еды из лучших ресторанов. Закажите вкусную еду на дом, участвуйте в акциях и бонусных программах. Отзывы и рейтинги на сайте dostavka05.ru."
                />
            </Head>
        )
    }


    return (
        <Head data-cy={'head-seo'}>
            <meta charSet="UTF-8"/>
            {
                seo.title && (
                    <title>
                        {decode(seo.title)}
                    </title>
                )
            }
            {
                seo.keywords && (
                    <meta
                        name="keywords"
                        content={seo.keywords}
                    />
                )
            }
            {
                seo.description && (
                    <meta
                        name="description"
                        content={seo.description}
                    />
                )
            }
            {children}
        </Head>
    )
}

export default HeadSeo
