import MainLayout from "common/layouts/MainLayout";
import { useCallback, useEffect } from "react";
import Partner from "modules/Partner";
import PartnerOpen from "modules/Partner/components/PartnerOpen";
import { wrapper } from "store";
import { links } from "helper";
import CartButton from "modules/Cart/components/CartButton";
import HeaderPartner from "shared/HeaderPartner";
import Card from "shared/Card";
import navRoute from "helper/navRoute";
import { usePartnerOpen } from "modules/Partner/hooks";
import HeadSeo from "shared/HeadSeo";
import DeviceDetect from "shared/DeviceDetect";

function PartnerOpenPage({ partnerSP, menuSP, isShopPartner }) {
    const { partner, onPartnerSet, partnerSyncMiniSite } = usePartnerOpen();
    const { onMenuSet, onMenuShopSet, menuReset } = Partner.hooks.useMenu();

    const navigation = navRoute();
    const onGoPartners = useCallback(() => {
        navigation.navigate(links.abs.partners);
    }, []);

    useEffect(() => {
        if (partner.data.id) {
            setTimeout(async () => {
                const isShopPartner = partner.data.menuViewType === 1;
                //const baseApi = location.href;
                const baseApi = location.href;
                if (isShopPartner) {
                    const menu =
                        await Partner.services.menuService.fetchShopMenuMiniSite(
                            null,
                        );
                    onMenuShopSet(menu);
                } else {
                    const menu = await Partner.services.menuService
                        .fetchMenuMiniSite
                        //baseApi,
                        ();
                    onMenuSet(menu);
                }
            }, 300);
        }
    }, [partner.data]);

    return (
        <MainLayout data-cy={"resorany-open-page"}>
            <HeadSeo seo={partner?.data?.seo} />
            <PartnerOpen />
            <DeviceDetect>
                <CartButton partnerOpenId={partner?.data?.alias} />
            </DeviceDetect>
        </MainLayout>
    );
}

/*export const getStaticProps = wrapper.getStaticProps(
    (store) => async (context) => {
    const { partnerId } = context.params;

    let config = {
        headers: {
            //'address-city': cityId
        }
    }

    const partner = await Partner.services.partnerService.fetchPartnerMiniSite(
        null,
        config,
    );
    //const partner = null
    console.log('partnerpartner', partner)


    //await store.dispatch(Partner.store.partner.actions.partnerSet(partner))

    //console.log('partnerpartner', partner)


    const isShopPartner = partner?.menuViewType === 1

    console.log('isShopPartner', isShopPartner)


    let menu = null;
    if(isShopPartner){
        //menu = await Partner.services.menuService.fetchShopMenuMiniSite(partnerId)
    }else{
        //menu = await Partner.services.menuService.fetchMenuMiniSite(config)
    }


    console.log('menumenu', menu)


    await Promise.all([
        await store.dispatch(Partner.store.partner.actions.syncMiniSite(null, config)),
        await store.dispatch(Partner.store.menu.actions.syncMiniSite(null, config))
    ])

    return {
        revalidate: 350,
        /!*props: {
            partnerSP: partner,
            menuSP: menu,
            isShopPartner
        }*!/
    }
    },
);*/

/*const generatePath = async () => {
    //const partners = ['gabriel', 'gambit']
    const partners = []

    let paths = []


    partners.map(data => {

        paths.push({ params: { partnerId:data }})
    })

    return paths
}


export async function getStaticPaths() {
    const paths = await generatePath()


    console.log('paths', paths)

    return {
        paths,
        fallback: true,
    };
}*/

export default PartnerOpenPage;
